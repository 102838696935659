import * as React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div className="hero has-text-centered py-6">
      <h1 className="title is-1">404: Not found</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
